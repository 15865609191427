/* fonts.css */
@font-face {
    font-family: 'CaroSoft';
    src: local('CarosSoft'), url('./assets/fonts/CarosSoft.otf') format('opentype');
    /* Add other font formats as needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CaroSoft';
    src: local('CarosSoftBold'), url('./assets/fonts/CarosSoftBold.otf') format('opentype');
    /* Add other font formats as needed */
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'CaroSoft';
    src: local('CarosSoftMedium'), url('./assets/fonts/CarosSoftMedium.otf') format('opentype');
    /* Add other font formats as needed */
    font-weight: 500; /* Adjust the font weight value for medium */
    font-style: normal;
  }
  